import React, { useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../AppContextProvider";
import { ServiceUrl } from "../../Components/Component";

export const ProdcutCard = ({
  product,
  HandelClosePopup,
  setShowSlecStore,
}) => {
  const width = window.innerWidth;
  const { selectedAdress, setSelectedAdress } = useAppContext();
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div
      key={product._id}
      className="sm:w-1/2 xl:w-[270px] md:w-[250px] mb-2 flex items-center justify-center py-2 cursor-pointer"
    >
      {product?.name && (
        <div
          onClick={
            selectedAdress?.storeAddress
              ? () => HandelClosePopup(true, product)
              : () => setShowSlecStore(true)
          }
          className="w-full relative bg-white flex flex-col rounded-2xl hover:shadow-lg transition-all duration-500 my-2"
        >
          {product.badges.new === true && (
            <label className="rounded-full absolute top-2 left-4  bg-green-200 px-2">
              {" "}
              new
            </label>
          )}
          <div className="md:pt-10 md:px-10 pt-2 px-2 self-center w-full xl:h-[270px] sm:h-[200px] flex items-center justify-center transition-transform duration-500">
            <img
              className="md:w-44 md:h-44 h-20 w-20 rounded transition-transform duration-500"
              src={`${ServiceUrl}/${product?.featured}`}
              alt={product.name}
            />
          </div>
          {/* Product Content */}
          <div className="md:px-6 px-2 pb-3 mt-auto transition-all duration-500 flex flex-col justify-between h-full min-h-[100px]">
            <div className="flex flex-col justify-between">
              {/* Price */}
              <div className="flex items-start pb-1">
                <p className="block md:text-2xl text-xs font-bold py-1 leading-none">
                  ${Math.floor(product?.rprice)}.
                  {(product?.rprice % 1).toFixed(2).split(".")[1]}
                </p>
              </div>

              {/* Product Name */}
              <div className="md:w-[220px] w-[110px] overflow-hidden">
                <p className="block text-gray-500 font-semibold text-xs md:text-sm line-clamp-2">
                  {product.name}
                  {product.badges?.new ? " true " : " false"}
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-">
            {selectedAdress?.storeAddress ? (
              <div
                onClick={() => {
                  // setSelectedProduct(product);
                  HandelClosePopup(true, product);
                }}
                style={{ bottom: "8px", right: "8px" }}
                className="scale-105 duration-700 shadow-md rounded-full text-gray-500 hover:text-white p-2 bg-white absolute   hover:bg-orange-500  duration-500 transition-all"
              >
                <svg
                  className="svg-icon"
                  viewBox="0 0 36 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={width < 400 ? "18" : "28"}
                  height={width < 700 ? "18" : "28"}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.832031 17.5962C0.832031 16.3349 1.42189 15.3125 2.14952 15.3125H33.7694C34.497 15.3125 35.0868 16.3349 35.0868 17.5962C35.0868 18.8574 34.497 19.8798 33.7694 19.8798H2.14952C1.42189 19.8798 0.832031 18.8574 0.832031 17.5962Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.9595 0.46875C19.2207 0.46875 20.2432 1.05861 20.2432 1.78624L20.2432 33.4061C20.2432 34.1337 19.2207 34.7236 17.9595 34.7236C16.6983 34.7236 15.6759 34.1337 15.6759 33.4061L15.6759 1.78624C15.6759 1.05861 16.6983 0.46875 17.9595 0.46875Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowSlecStore(true);
                }}
                style={{ bottom: "8px", right: "8px" }}
                className="scale-105 duration-700 shadow-md rounded-full text-gray-500 hover:text-white p-2 bg-white absolute   hover:bg-orange-500  duration-500 transition-all"
              >
                <svg
                  className="svg-icon"
                  viewBox="0 0 36 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width={width < 400 ? "18" : "28"}
                  height={width < 700 ? "18" : "28"}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.832031 17.5962C0.832031 16.3349 1.42189 15.3125 2.14952 15.3125H33.7694C34.497 15.3125 35.0868 16.3349 35.0868 17.5962C35.0868 18.8574 34.497 19.8798 33.7694 19.8798H2.14952C1.42189 19.8798 0.832031 18.8574 0.832031 17.5962Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.9595 0.46875C19.2207 0.46875 20.2432 1.05861 20.2432 1.78624L20.2432 33.4061C20.2432 34.1337 19.2207 34.7236 17.9595 34.7236C16.6983 34.7236 15.6759 34.1337 15.6759 33.4061L15.6759 1.78624C15.6759 1.05861 16.6983 0.46875 17.9595 0.46875Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProdcutCard);
