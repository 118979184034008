// fetchData.js
import axios from 'axios';
import { useAppContext } from '../AppContextProvider';
import moment from "moment";

export const fetchUserData = async (setUserData, setSelectedAdress) => {
    const token = localStorage.token;
    let activeAddress;

    try {
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }
        const response = await axios.get(`${ServiceUrl}/api/main/profile`, {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });
        setUserData(response.data);


        activeAddress = response.data?.addresses?.find(address => address.active === true);
        if (token) {
            fetchStoreData(activeAddress, setSelectedAdress)
        }

        setSelectedAdress(activeAddress); // Update address state once user data is fetched
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

export const fetchStoreData = async (selectedAdress, setSelectedAdress) => {
    const token = localStorage.token;
    const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
    const selectedLocation = savedLocations?.find((location) => location.active);

    try {
        const lati = token ? selectedAdress?.coordinates?.lat : selectedLocation?.lat;
        const long = token ? selectedAdress?.coordinates?.lng : selectedLocation?.lng;

        const response = await axios.get(`${ServiceUrl}/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });

        if (response.data?.storeName) {

            setSelectedAdress(prevFormData => ({
                ...prevFormData,
                ...response.data,
            }));
        } else {
            cheqapi(setSelectedAdress)
        }

    } catch (error) {

        cheqapi(setSelectedAdress)

    }

};

export const cheqapi = async (setSelectedAdress) => {
    try {

        // Await the axios.get call to ensure the data is loaded before proceeding
        const response = await axios.get(`${ServiceUrl}/api/main/products`, {
            withCredentials: false
        });
        const data = response.data;
        // Filtering the data based on the store._id
        const filtered_data = data.filter(x => x?.store?._id === '6675cbcdf34a17ea3d0de9fa');
        // Sorting the filtered data by the name property
        const sortedData = filtered_data.sort((a, b) => a.name.localeCompare(b.name));

        setSelectedAdress(prevFormData => ({
            ...prevFormData,
            products: sortedData,
        }));
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};



export const capitalizeName = (name) => {
    if (!name) return '';
    return name.split(' ').map(word => {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join(' ');
}

export const copyToClipboard = (shareLink, setCopySuccess) => {
    navigator.clipboard.writeText(shareLink)
        .then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 4000); // Clear message after 2 seconds
        })
        .catch((err) => console.error('Failed to copy!', err));
};

const inputDateCulture = [
    'D-MM-YYYY', 'M-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 
    'DD/MM/YYYY', 'YYYY/MM/DD', 'D-MMM-YYYY', 'MMM DD YYYY hh:mmA', 
    moment.ISO_8601
];

export function getCorrectDate(str) {
    const parsedDate = moment(str, inputDateCulture, false); // Removing strict parsing
    if (parsedDate.isValid()) {
        if (parsedDate.format('DD-MM-YYYY') === '01-01-1900') {
            return null;
        }
        return parsedDate; // Return a moment object instead of a formatted string
    }
    return null;
}

export function isExpired(expirationDateStr) {
    const expirationDate = moment(expirationDateStr); // Parse expiration date
    const now = moment(); // Get current date and time

    return expirationDate.isBefore(now); // Returns true if expired
}

export const formatDateTime = (isoString) => {
    const dateObj = new Date(isoString);

    // Format Date (YYYY-MM-DD)
    const formattedDate = dateObj.toISOString().split("T")[0];

    // Get Hours, Minutes, and AM/PM format
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert '0' hour to '12'
    minutes = minutes.toString().padStart(2, "0"); // Ensure two-digit minutes

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return ` ${formattedDate},  ${formattedTime}`;
};

// Example usage
const message = formatDateTime("2025-01-27T14:43:26.539Z");


export const ServiceUrl = 'https://api.vapedelivery.com'


