import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function Policy() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className=" text-start max-w-screen-xl">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 ">PRIVACY POLICY – VAPEDELIVERY.COM
                </h1>
                <div>
                    <p className='font-medium text-lg'>A. INTRODUCTION.</p>
                    <p className='ml-2'>
                        <br /> 1.	Vape delivery respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Privacy Policy”). This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit the website Vapedelivery.com (our “Website”), and our practices for collecting, using, maintaining, protecting, and disclosing that information.

                        <br /><br /> 2.	This Privacy Policy applies to information we collect:
                        a	On the Website.
                        b	In email, text, and other electronic messages between you and (i) the Website or (ii) the Company.

                        <br /><br />3	It does not apply to information collected by:
                        a	Company offline or through any other means, including on any other website operated by Company or any third party (including Stores using our Platform); or
                        b	Any third party including through any application or content (including advertising) that may link to or be accessible from or on the Website.
                        <br /><br />4. YOUR ACCEPTANCE OF THIS PRIVACY POLICY. PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY ACCESSING OR USING THIS WEBSITE, YOU AGREE TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR CHOICE IS NOT TO USE OUR WEBSITE. THIS PRIVACY POLICY MAY CHANGE FROM TIME TO TIME (SEE BELOW “CHANGES TO OUR PRIVACY POLICY”). YOUR CONTINUED USE OF THIS WEBSITE AFTER WE MAKE CHANGES IS DEEMED TO BE ACCEPTANCE OF THOSE CHANGES, SO PLEASE CHECK THE PRIVACY POLICY PERIODICALLY FOR UPDATES.
                        <br /><br />5. Definitions. For convenience we use in this Privacy Policy several defined terms:
                        <br /><br />a. Business Vendors. We deal with several third-party vendors (the “Business Vendors”) whose services are directly related to the operation and maintenance of the Website, including payment processing, analyzing aggregate data, customer service, and hosting and technology services, and we share with them some of your data for business purposes related to your purchase of goods or services from the Stores or the operation and maintenance of the Website. We are not responsible for their handling of your data. You need to review their respective privacy policies and decide whether or not you agree with their respective privacy policies. If you do not agree with them, your choice is to not use our Website. These Business Vendors include and their respective privacy policies (as of the above revision date for this Privacy Policy) are:
                        <br />i	AWS: https://aws.amazon.com/privacy/ (https://aws.amazon.com/privacy/)
                        <br />ii	Facebook: https://www.facebook.com/policy.php (https://www.facebook.com/policy.php)
                        <br />iii	FullStory: https://www.fullstory.com/legal/privacy/ (https://www.fullstory.com/legal/privacy/)
                        <br /> iv	Google: https://policies.google.com/privacy (https://policies.google.com/privacy)
                        <br /> v	Heroku: https://www.heroku.com/policy/security (https://www.heroku.com/policy/security)
                        <br />vi	Mixpanel: https://mixpanel.com/legal/privacy-overview/ (https://mixpanel.com/legal/privacy-overview/)
                        <br />vii	New Relic: https://newrelic.com/termsandconditions/privacy (https://newrelic.com/termsandconditions/privacy)
                        <br />viii	Rollbar: https://docs.rollbar.com/docs/privacy-policy (https://docs.rollbar.com/docs/privacy-policy)
                        <br />ix	https://spectrumepay.com/ (https://spectrumepay.com/privacy-policy/)
                        <br />x	Braintree: https://www.braintreepayments.com/legal/braintree-privacy-policy (https://www.braintreepayments.com/legal/braintree-privacy-policy)
                        <br />xi	Fiserv: https://www.fiserv.com/en/about-fiserv/privacy-notice.html (https://www.fiserv.com/en/about-fiserv/privacy-notice.html)
                        <br />xii	Forter: https://www.forter.com/privacy-policy (https://www.forter.com/privacy-policy)
                        <br /><br />b.	Platform. The Platform consists of our Website operated in conjunction with the use of certain services provided by the Business Vendors.
                        <br /><br />c.	Stores. The Stores are third-party businesses who have chosen to use our Website and Platform to provide delivery of their respective goods and services to you. We do not manufacture, sell, or provide any goods or services you order using the Website. Our Website and Platform simply provide you with a convenient way for you to order and have delivered to you by the Stores the goods and services that you order using the Website and Platform.
                    </p>
                    <p className='font-medium text-lg py-4'> C. INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT.</p>
                    <p className='ml-2'>
                        <b>  1.</b>	We collect several types of information from and about users of our Website, including information:
                        <br /><br />  a)	By which you may be personally identified, such as name, postal address, e-mail address, telephone number, instructions or information you provide to effectuate delivery of an order you place, alphanumeric loyalty codes, or any other identifier by which you may be contacted online or offline (”Personal Information”);
                        <br /><br /> b)	That is about you but individually does not identify you, such as geo-location information to assist the delivery driver in locating the place of delivery of an order you place; and/or
                        <br /><br /> c)	About your Internet connection, the equipment you use to access our Website, and usage details.


                        <br /><br /><b>  2.</b>	We collect this information:
                        <br /><br /> a)	Directly from you when you provide it to us.
                        <br /><br /> b)	Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, tracking pixels, and other tracking technologies.
                        <br /><br /> c)	From third parties, for example, our Business Vendors.
                    </p>
                    <p className='font-medium text-lg py-4'> D.	INFORMATION YOU PROVIDE TO US.</p>
                    <p className='ml-2'>
                        1	The information we collect on or through our Website may include:
                        <br /><br />  a	Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use, or when using, our Website (including Personal Information you provide), registering for our service, ordering a delivery from one or more of the Stores using our Platform, or requesting further services. We may also ask you for information when you report a problem with our Website.
                        <br /><br />  b	Records and copies of your correspondence (including e-mail addresses), if you contact us.
                        <br /><br />  i)	Your responses to surveys that we might ask you to complete for research purposes.
                        <br /><br />   ii)	Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website. When you provide financial information, such as your credit card information (“CII”), you are leaving our Website and will be entering that information on a third-party’s website, and will be subject to the privacy policy (and terms of use) of that third-party website. The only CII we receive when you enter your financial information is:
                        <br /> ▪ Last 4 digits of credit card number.
                        <br />▪	 Credit card expiration date.
                        <br />▪	 Billing address.
                        <br /> ▪ Card holder name.
                        <br /><br />  iii	This CII is used by us to track your orders, identify your transactions in the event a refund is warranted, and for other purposes described below in the section “How We Use Your Information”).
                        <br /><br />  c	Your search queries on the Website.
                    </p>

                    <p className='font-medium text-lg py-4'>E.	INFORMATION WE COLLECT THROUGH AUTOMATIC DATA COLLECTION TECHNOLOGIES.
                    </p>
                    <p className='ml-4'>
                        <b>  1.</b>.	As you navigate through and interact with our Website, we and our Business Vendors may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
                        <br /><br />  a.	Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.
                        <br /><br />  b.	Information about your computer and Internet connection, including your IP address, operating system, and browser type.
                        <br /><br />  <b>  2.</b>	We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).
                        <br /><br /> <b>  3.</b>The information we collect automatically may be statistical data (including, without limitation, clicks, scrolls, and other visitor movement and navigation in the use of our Website) and does include Personal Information, or we may maintain it or associate it with Personal Information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:
                        <br /><br />  a.	Estimate our audience size and usage patterns.
                        <br /><br />  b.	Store information about your preferences, allowing us to customize our Website according to your individual interests.
                        <br /><br />  c.	Speed up your searches.
                        <br /><br />  d.	Recognize you when you return to our Website.
                        <br /><br />  <b>  4.</b>The technologies we use for this automatic data collection may include:
                        <br /><br />  a.	Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
                        <br /><br />  b.	Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see below “Choices About How We Use and Disclose Your Information.” </p>


                    <p className='font-medium text-lg py-4'>F.	THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES. Some
                    </p>
                    <p className='ml-4'>
                        content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with other tracking technologies to collect information about you when you use our Website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with IBA or other targeted content. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see below “Choices About How We Use and Disclose Your Information.”
                    </p>

                    <p className='font-medium text-lg py-4'>G.	HOW WE USE YOUR INFORMATION. We use information that we collect about you or that you provide to us, including any personal information:
                    </p>
                    <p className='ml-4'>
                        1	To present our Website and its contents to you.
                        <br /><br />   2	To provide you with information, products, or services that you request from us.
                        <br /><br />   3	To fulfill any other purpose for which you provide it.
                        <br /><br />   4	To provide you with notices about your account, including expiration and renewal notices, if applicable to your account.
                        <br /><br />   5	To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
                        <br /><br />   6	To notify you about changes to our Website or any products or services we offer or provide though it.
                        <br /><br />   7	In any other way we may describe when you provide the information.
                        <br /><br />   8	For any other purpose with your consent.
                        <br /><br />   9	We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria. Any information you provide to third parties (including third party advertisers) are subject to the privacy policies of those third parties.
                    </p>
                    <p className='font-medium text-lg py-4'> H.	DISCLOSURE OF YOUR INFORMATION. We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
                    </p>
                    <p>
                        <b>  1.</b>	We may disclose personal information that we collect or you provide as described in this Privacy Policy:
                        <br /><br />   a.	To our subsidiaries and affiliates;
                        <br /><br />   b.	To contractors, service providers, Business Vendors, and other third parties whose services are directly related to your purchase of goods or services from the Stores or the operation and maintenance of the Website, including payment processing, analyzing aggregate data, customer service, and hosting and technology services and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them and for no other purposes;
                        <br /><br />  c.	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Vape Delivery assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Vroom Delivery, Inc., about our Website users is among the assets transferred;
                        <br /><br />  d.	To third parties to market their products or services to you if you have opted in to these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see below “Choices About How We Use and Disclose Your Information;”
                        <br /><br />  e.	To fulfill the purpose for which you provide it. For example, if you give us an e-mail address to use when using the delivery ordering feature of our Website, we will transmit the contents of that email and your email address to the recipients;
                        <br /><br />   f.	For any other purpose disclosed by us when you provide the information;
                        <br /><br />   g.	With your consent; and/or
                        <br /><br /> <b>  2.</b>We may also disclose your personal information:
                        <br /><br />  a.	To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                        <br /><br />   b.	To enforce or apply our terms of use (https://www.vapedelivery.com) and other agreements, including for billing and collection purposes.
                        <br /><br />  c	.If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Vroom Delivery, Inc., our customers, Business Vendors, Stores, or others. This includes, subject to applicable law, exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                    </p>

                    <p className='font-medium text-lg py-4'>I.	CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION.
                    </p>
                    <p>
                        <b>  1.</b>	We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:
                        <br /><br />a.	Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. As of the revision date at the top of this Privacy Policy, a link to the Flash player settings is available here (https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html). Note, if you disable or refuse cookies, then some parts of this website may become inaccessible or not function properly.
                        <br /><br />b.	Disclosure of Your Information for Third-Party Advertising. If you have opted in and later decide that you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by contacting us at support@vapedelivery.com.
                        <br /><br />c.	Promotional Offers from the Company. If you do not wish to have your e-mail address and/or your other contact information used by the Company to promote our own or third parties’ products or services, you can opt-out by contacting us at support@vapedelivery.com If we have sent you a promotional email, you may send us a return e-mail, or forward the e-mail to the abovementioned e-mail address, asking to be omitted from future email distributions. This opt-out does not apply to information provided to the Company as a result of a delivery service experience, or other transactions between you and the Company.
                        <br /><br />d.	Targeted Advertising. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by contacting us at support@vapedelivery.com.
                        <br /><br />e.	We do not control third parties’ collection or use of your information to serve IBA. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (”NAI”) on the NAI’s website (http://optout.networkadvertising.org/?c=1).
                    </p>
                    <p className='font-medium text-lg py-4'>J	ACCESSING AND CORRECTING YOUR INFORMATION. If you need to access and/or change any information you provide to us, then please send an e-mail to us at support@vapedelivery.com and identify your name, the specific information you want to access and/or change, and the reason for the change.
                    </p>
                    <p className='font-medium text-lg py-4'>K.	DATA SECURITY.
                    </p>
                    <p className='ml-4'>
                        <b>  1.</b>	We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using TLS technology.
                        <br /><br />  <b>  2.</b>	The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential and for selecting a strong password. We ask you not to share your password with anyone. We suggest you do not share your personal identifying information (including, without limitation, your credit card information) using a public network to connect to the Internet.
                        <br /><br />  <b>  3.</b>	Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
                        <br /><br />   <b>  4.</b>	We encourage you to educate yourself about online security. One resource for you to consider is the Federal Trade Commission (“FTC”) information on online security. As of the revision date at the top of this Privacy Policy, a link to that FTC information is https://www.consumer.ftc.gov/topics/online-security (https://www.consumer.ftc.gov/topics/online-security).
                    </p>
                    <p className='font-medium text-lg py-4'>L.	CHANGES TO OUR PRIVACY POLICY.
                    </p>
                    <p className='ml-4'>
                        <b>  1.</b>	We reserve the right to change our Privacy Policy. It
                        is our policy to post any changes we make to our Privacy Policy on this page. Check the revision date at the top of this page to see if it is a different date than the date of our Privacy Policy version you reviewed and accepted as stated above. If we make material changes to how we treat our users’ personal information, we will notify you through a notice on the Website home page and/or by sending an e-mail to you at the e-mail address you have provided to us. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our Website and this Privacy Policy to check for any changes.
                    </p>
                    <p className='font-medium text-lg py-4'>M.
                        CONTACT INFORMATION.
                    </p>
                    <p className='ml-4'>
                        We want to hear from you if you have any questions or concerns regarding our Privacy Policy. To ask questions or comment about this Privacy Policy and our privacy practices, please contact us at:
                        support@vapedelivery.com
                    </p>
                </div>

            </div>
        </div >
    )
}
