import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import axios from 'axios';
import { useAppContext } from '../AppContextProvider';
import Swal from 'sweetalert2';
import { fetchUserData, isExpired, ServiceUrl } from '../Components/Component';
// import fetchData from '../Components/Component';
const libraries = ['places'];

export default function SaveLocation({ }) {
    let navigate = useNavigate();
    const { popHandel, setPopHandel } = useAppContext();

    const token = localStorage.token;
    const [formData, setFormData] = useState(false);
    const [savedLocation, setsavedLocation] = useState(true);
    const { selectedAdress, setSelectedAdress } = useAppContext()
    const { userData, setUserData } = useAppContext();
    const [savedLocations, setSavedLocations] = useState(
        () => {
            const saved = localStorage.getItem('savedLocations');
            return saved ? JSON.parse(saved) : [];
        }
    );

    const [searchBox, setSearchBox] = useState(null);
    const onLoad = ref => {
        setSearchBox(ref);
    };

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        const place = places[0];
        if (place) {
            const name = place.name;
            const addressComponents = place.address_components;
            const getComponent = type => addressComponents.find(component => component.types.includes(type));
            const state = getComponent('administrative_area_level_1')?.long_name || null;
            const city = getComponent('locality')?.long_name || null;
            const zipcode = getComponent('postal_code')?.long_name || null;
            const streetNumber = getComponent('street_number')?.long_name || null;
            const streetName = getComponent('route')?.long_name || null;
            const street = streetNumber && streetName ? `${streetNumber} ${streetName}` : null;

            // /00000


            var tax = 0
            if (state == "Pennsylvania") {
                tax = 6.0
            } else if (state == "Texas") {
                tax = 6.25
            } else {
                tax = 6
            }
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            setFormData({ lat, lng, name, street, state, zipcode, tax, city });

            // setMapData({ location: { lat: location.lat(), lng: location.lng() }, name, street, state, zipcode, tax, city });
            setsavedLocation(false);
        }
    };
    const handleGetCurrentLocation = () => {

        
        
    


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;

                    // Reverse Geocode to Get Address
                    const geocoder = new window.google.maps.Geocoder();
                    const latLng = { lat: latitude, lng: longitude };

                    geocoder.geocode({ location: latLng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            const place = results[0];
                            const addressComponents = place.address_components;

                            const getComponent = type =>
                                addressComponents.find(component => component.types.includes(type));

                            const state = getComponent('administrative_area_level_1')?.long_name || null;
                            const city = getComponent('locality')?.long_name || null;
                            const zipcode = getComponent('postal_code')?.long_name || null;
                            const streetNumber = getComponent('street_number')?.long_name || null;
                            const streetName = getComponent('route')?.long_name || null;
                            const street = streetNumber && streetName ? `${streetNumber} ${streetName}` : null;

                            // Determine Tax Based on State
                            let tax = 0;
                            if (state === "Pennsylvania") {
                                tax = 6.0;
                            } else if (state === "Texas") {
                                tax = 6.25;
                            } else {
                                tax = 6.0;
                            }

                            // Populate Form Data
                            setFormData({
                                lat: latitude,
                                lng: longitude,
                                name: place.formatted_address, // Full formatted address
                                street,
                                state,
                                zipcode,
                                tax,
                                city,
                            });

                            // Update Input Field
                            const input = document.querySelector('input[placeholder="Address"]');
                            if (input) {
                                input.value = place.formatted_address;
                            }

                            // Optional: Adjust Map View
                            if (map) {
                                map.panTo(latLng);
                            }
                            setsavedLocation(false);

                        } else {
                            console.error('Geocode was not successful for the following reason: ' + status);
                        }
                    });
                },
                error => {
                    console.error('Error fetching location: ', error);
                }
            );
        } else {
            alert('Geolocation is not supported by your browser');
        }
    };

    const editLocation = (location) => {
        setFormData(location)
        setsavedLocation(false);
    }

    const saveLocation = (location) => {
        const data = {
            ...formData,
            id: (formData.lat + formData.lng),
            buildingtype: formData.type || `ID--${formData.lat + formData.lng}`,
            label: formData.label || "-",
            active: true
        };

        // Update saved locations, set the new one as checked and others as unchecked
        const updatedSavedLocations = savedLocations.map((item) => ({
            ...item,
            active: false
        }));

        // Check if the location already exists

        if (token) {
            const locationExists = userData.addresses?.some((x) => x?.lat === formData.lat && x?.lng === formData?.lng);
            if (locationExists) {
                Swal.fire({
                    icon: 'warning',
                    title: `Location already exists token`,
                    showConfirmButton: true,
                });
                setsavedLocation(true);
            } else {
                axios.post(`${ServiceUrl}/api/main/add-address`, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    withCredentials: false
                }).then(response => {
                    const activeAddress = response.data?.addresses?.find(address => address.active === true);
                    setSelectedAdress(activeAddress);
                    Swal.fire({
                        icon: 'sucess',
                        title: `address save Successful`,
                        showConfirmButton: true,
                    });
                    setsavedLocation(true);
                    fetchUserData(setUserData, setSelectedAdress)
                    navigate('/product');

                }).catch(error => {

                    let errorMessage = '';

                    // Check if error.response.data is an array or an object
                    if (Array.isArray(error.response.data)) {
                        // If it's an array, format each item as a string
                        errorMessage = error.response.data.map((item) => {
                            return `${item.label ? `label: ${item.label}` : ''}, ${item.street ? `Street: ${item.street}` : ''}${item.zipcode ? `, Zipcode: ${item.zipcode}` : ''}`;
                        }).join('\n');
                    } else if (error.response.data && typeof error.response.data === 'object') {
                        // If it's an object, format it as a string
                        errorMessage = `${error.response.data.label ? `label: ${error.response.data.label}` : ''}, ${error.response.data.street ? `Street: ${error.response.data.street}` : ''}${error.response.data.zipcode ? `, Zipcode: ${error.response.data.zipcode}` : ''}`;
                    } else {
                        // errorMessage = 'Unexpected error structure.';
                        // localStorage.removeItem('token');
                        // localStorage.removeItem('userid');
                        // localStorage.removeItem("appValue")
                        // window.location.reload();
                        // navigate('/product')
                    }
                    console.error('Error fetching data:', error);
                    Swal.fire({
                        icon: 'error',
                        title: errorMessage,
                        showConfirmButton: true,
                    });
                });
            }
        }
        else {
            const locationExists = updatedSavedLocations.some((x) => x?.lat === formData.lat && x?.lng === formData?.lng);
            if (locationExists) {
                Swal.fire({
                    icon: 'warning',
                    title: `Location already exists local`,
                    showConfirmButton: true,
                });
                setsavedLocation(true);
            } else {
                updatedSavedLocations.push(data);
                handelSubmit(data);
                localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
                setSavedLocations(updatedSavedLocations);
                setsavedLocation(true);
                navigate('/product');
            }
        }

    };

    const deleteLocation = (indexToDelete) => {
        const locationToDelete = savedLocations[indexToDelete];
        const updatedSavedLocations = savedLocations.filter((_, index) => index !== indexToDelete);
        // Update the localStorage
        localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
        setSavedLocations(updatedSavedLocations);

        // Check if the location to be deleted is the currently selected one
        if (locationToDelete.active) {
            // If it is, set the first location as selected if there are any locations left
            if (updatedSavedLocations.length > 0) {
                const newSelectedLocation = { ...updatedSavedLocations[0], active: true };
                updatedSavedLocations[0] = newSelectedLocation;
                localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
                setSavedLocations(updatedSavedLocations);
            } else {
                setsavedLocation(false);
            }
        }
        setsavedLocation(true);
        navigate('/product');
    };
    const deleteLocationapi = (_id) => {
        axios.delete(`${ServiceUrl}/api/main/remove-address/${_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            withCredentials: false
        }).then(response => {
            Swal.fire({
                icon: 'sucess',
                title: `adress Deleted Successful`,
                showConfirmButton: true,
            });
            setsavedLocation(true);
            navigate('/product');
            fetchUserData(setUserData, setSelectedAdress)

        }).catch(error => {
            console.error('Error fetching data:', error);
        });

    };

    const selectStoreApi = (id) => {
        let activeAddress;
        axios.patch(`${ServiceUrl}/api/main/set-address/${id}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`  // Add Authorization header only if token exists
            },
            withCredentials: false,
        })
            .then(async response => {
                // setUserData(...response.data);
                activeAddress = response.data?.addresses?.find(address => address.active === true);
                // setSelectedAdress(activeAddress);
                // await handelSubmit(activeAddress)
                setsavedLocation(true);
                fetchUserData(setUserData, setSelectedAdress)
                navigate('/product');
            })
            .catch(error => {
                // setselectedStore({ selectedStore });
                // setUserData(response.data);
                navigate('/product');
            });

    }
    const handelSubmit = (e) => {
        const token = localStorage.token
        const lati = token ? e?.coordinates?.lat : e?.lat;
        const long = token ? e?.coordinates?.lng : e?.lng;
        // const location = {
        //     name: e.street,
        //     state: e.state,
        //     tax: 0,
        //     zipcode: e.zipcode,
        //     business: e.type,
        //     location: e.label
        // }
        const updatedSavedLocations = savedLocations.map((item) => {
            if (item.id === (lati + long)) {//--- changed
                return { ...item, active: true };
            }
            return { ...item, active: false };
        });
        localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
        setSavedLocations(updatedSavedLocations);


        axios.get(`${ServiceUrl}/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: {
                'Authorization': `Bearer ${token}`  // Add Authorization header only if token exists
            },
            withCredentials: false,
        })
            .then(response => {
                setSelectedAdress({ ...response.data, ...e });

                navigate('/product');
                setsavedLocation(true);

            })
            .catch(error => {
                setSelectedAdress(e);
                navigate('/product');
            });

    }

    const handleChange = (e) => {
        if (e == "Home" || e == "Office" || e == "Other") {
            setFormData({
                ...formData,
                type: e === "Other" ? `ID--${formData.lat + formData.lng}` : e
            })
        } else {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }
    const businessAndHomeAddresses = userData?.addresses?.filter(
        (location) => location.type === 'Office' || location.type === 'Home'
    );

    const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
    const [map, setMap] = useState(null);
    const searchBoxRef = useRef(null);


    // const onLoad = ref => {
    //     searchBoxRef.current = ref;
    // };

    // const onPlacesChanged = () => {
    //     const places = searchBoxRef.current.getPlaces();
    //     console.log(places); // Handle selected place
    // };


    return (
        <div className="fixed top-0  scrollbar overflow-y-auto right-0 h-full top-0 z-20 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg shadow w-full md:w-2/3 xl:w-1/3">
                {savedLocation ? <svg onClick={() => { 
                    navigate('/product'); setsavedLocation(true) 
                    
                    }} className='absolute top-3 end-2.5 cursor-pointer rounded-full hover:shadow-md' width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* <circle cx="16.3242" cy="16.0781" r="16" fill="#FF4141" /> */}
                    <path d="M21.6654 10.7349C21.335 10.4045 20.7994 10.4045 20.469 10.7349L16.3242 14.8797L12.1793 10.7349C11.8489 10.4045 11.3133 10.4045 10.9829 10.7349C10.6526 11.0653 10.6526 11.6009 10.9829 11.9313L15.1278 16.0761L10.9829 20.221C10.6526 20.5513 10.6526 21.087 10.9829 21.4173C11.3133 21.7477 11.8489 21.7477 12.1793 21.4173L16.3242 17.2725L20.469 21.4173C20.7994 21.7477 21.335 21.7477 21.6654 21.4173C21.9957 21.087 21.9957 20.5513 21.6654 20.221L17.5205 16.0761L21.6654 11.9313C21.9957 11.6009 21.9957 11.0653 21.6654 10.7349Z" fill="black" />
                </svg>
                    :
                    ''
                }
                {savedLocation ?
                    <div className="p-4 md:p-5 mt-2 w-full">
                        <h3 className="mb-5 text-xl text-center font-bold text-gray-700 ">Choose address</h3>
                        <LoadScript
                            googleMapsApiKey="AIzaSyDTF_IHcL1fDOskOfGnX_cYjcc2udxBfRU"
                            libraries={libraries}
                            loadingElement={<>Loading...</>}>
                            <GoogleMap
                                id="search-box-example"
                                mapContainerStyle={{
                                    height: '40px',
                                    marginBottom: '0.5rem',
                                    display: 'flex',
                                    width: '100%',
                                    borderRadius: '0.25rem',
                                    background: 'white'
                                }}
                                className="mb-2 flex w-full  p-1 rounded border-2 border-gray-200"
                                zoom={0}>
                                <StandaloneSearchBox
                                    onLoad={onLoad}
                                    onPlacesChanged={onPlacesChanged}>
                                    <input
                                        type="text"
                                        placeholder="Add new address"
                                        style={{
                                            boxSizing: 'border-box',
                                            border: '1px solid transparent',
                                            width: '100%',
                                            height: '40px',
                                            padding: '10px 12px',
                                            borderRadius: '0.25rem',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                            fontSize: '14px',
                                            outline: 'none',
                                            borderColor: '#e5e7eb', // Matches `border-gray-200`
                                            backgroundColor: '#F0F0F0',
                                            textOverflow: 'ellipses',
                                            position: 'absolute',
                                            transition: 'background-color 0.2s, border-color 0.2s', // Matches `transition-colors`

                                        }}
                                        onBlur={(e) => {
                                            e.target.style.backgroundColor = '#F0F0F0'; // Revert to default
                                            e.target.style.borderColor = '#e5e7eb'; // Revert to default
                                        }}
                                        onFocus={(e) => {
                                            e.target.style.backgroundColor = 'white'; // Matches `focus:bg-white`
                                            e.target.style.borderColor = '#fb923c'; // Matches `focus:border-orange-500`
                                        }}
                                    />
                                </StandaloneSearchBox>
                            </GoogleMap>
                            <button className={` flex items-center justify-center gap-x-2 px-4 py-2 my-4 rounded-md transition-colors cursor-pointer`} onClick={handleGetCurrentLocation}>
                                <svg width="25" height="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M29.3483 3.16797L14.6816 17.8346M29.3483 3.16797L20.015 29.8346L14.6816 17.8346M29.3483 3.16797L2.68164 12.5013L14.6816 17.8346" stroke="#5A5A5A" stroke-width="3" stroke-linecap="round" strokeLinejoin="round" />
                                </svg>
                                <p className="sm:text-md text-md text-start font-semibold	 text-gray-600 pl-2 "><label className=''>Get My Current Location</label></p>
                            </button>
                        </LoadScript>
                        <hr />
                        <p className="px-2 py-1 pt-4 text-md text-start font-semibold text-[#000000] ">Saved address</p>
                        <div className='scrollbar overflow-y-auto h-[60vh] w-full'>
                            {token ?
                                <><div className="w-full pt-2  p-3 rounded-md space-y-1">
                                    {/* Business and Home addresses in a row */}
                                    {/* <div className="w-full my-1 flex justify-between gap-x-1 border-b  border-b-2 pb-1 items-center ">
                                            {businessAndHomeAddresses?.map((location, index) => (
                                                <div key={index} className={`w-1/2 mt-1  rounded-md  `}>
                                                    <p className="mb-1 px-3 w-full sm:text-sm text-md text-start font-semibold text-gray-500 "><label className=''>{location?.type} </label></p>
                                                    <div className={`'w-full   flex  justify-between items-center p-3 rounded-md ${location.type === 'Office' ? 'bg-blue-200' : 'bg-green-200'} `}>
                                                        <div onClick={() => selectStoreApi(location._id)} className='cursor-pointer'>
                                                            <p className="mb-1 sm:text-sm text-md text-start font-normal text-gray-500 "><label className=''> {location?.label ? location?.label + ', ' : ''} </label>{location?.street}</p>
                                                        </div>
                                                        {location.active === true ?
                                                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.78516 9.0798L8.6423 15.9369L22.3567 2.22266" stroke="#F95424" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            :
                                                            <svg onClick={() => deleteLocationapi(location._id)} className=" cursor-pointer   " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545"></path></svg>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div> */}

                                    {/* Other addresses in column format */}
                                    {userData?.addresses?.map((location, index) => (
                                        <div key={index} className={`w-full ${location.active === true && 'bg-gray-100'} mt-1 flex justify-between items-center p-3 rounded-xl`}>
                                            <div className='flex items-center  gap-x-3 '>
                                                {location.type === 'Office' || location.type === 'Home' ? '' : <>
                                                    {location.active ? <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8164 12.332C24.8164 21.6654 12.8164 29.6654 12.8164 29.6654C12.8164 29.6654 0.816406 21.6654 0.816406 12.332C0.816406 9.14943 2.08069 6.09719 4.33112 3.84675C6.58156 1.59631 9.63381 0.332031 12.8164 0.332031C15.999 0.332031 19.0513 1.59631 21.3017 3.84675C23.5521 6.09719 24.8164 9.14943 24.8164 12.332ZM12.8164 16.332C15.0255 16.332 16.8164 14.5412 16.8164 12.332C16.8164 10.1229 15.0255 8.33203 12.8164 8.33203C10.6073 8.33203 8.81641 10.1229 8.81641 12.332C8.81641 14.5412 10.6073 16.332 12.8164 16.332Z" fill="black" />
                                                    </svg>
                                                        : <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21.8878 11.1658V11.1669C21.8872 12.9813 21.4061 14.7629 20.4939 16.3307L20.4858 16.341L20.4711 16.3631C19.9911 17.0801 19.4716 17.7698 18.9149 18.4289L18.9017 18.4445L18.8894 18.4608L12.5674 26.8302L12.5664 26.8316C12.4505 26.9858 12.3003 27.1109 12.1277 27.1971C11.9552 27.2833 11.7649 27.3281 11.572 27.3281C11.3791 27.3281 11.1889 27.2833 11.0163 27.1971C10.8437 27.1109 10.6936 26.9858 10.5777 26.8316L10.5766 26.8301L4.15462 18.3305C3.9271 18.0184 3.70006 17.741 3.49704 17.493C3.44328 17.4273 3.3912 17.3637 3.34124 17.302C3.0952 16.9983 2.88721 16.7265 2.70505 16.418L2.68171 16.3784L2.65372 16.342L2.65109 16.3386C1.86048 14.9805 1.39039 13.4597 1.27692 11.8919C1.16236 10.309 1.41446 8.72094 2.01363 7.25131C2.61281 5.78168 3.54291 4.47006 4.73159 3.41845C5.92027 2.36685 7.33549 1.60361 8.86721 1.18808C10.3989 0.772551 12.0059 0.715938 13.563 1.02264C15.1202 1.32934 16.5856 1.9911 17.8454 2.95644C19.1051 3.92178 20.1252 5.16469 20.8263 6.58851C21.5274 8.01233 21.8907 9.57868 21.8878 11.1658ZM8.44443 15.7907C9.3696 16.4081 10.4571 16.7373 11.5694 16.7365C13.06 16.7356 14.4893 16.1427 15.543 15.0883C16.5968 14.0339 17.1887 12.6043 17.1887 11.1136C17.1887 10.0013 16.8588 8.91403 16.2408 7.98925C15.6228 7.06448 14.7444 6.34378 13.7167 5.91829C12.689 5.49281 11.5583 5.38167 10.4674 5.59892C9.37656 5.81618 8.37465 6.35209 7.58841 7.13883C6.80217 7.92558 6.26691 8.92783 6.05036 10.0188C5.83381 11.1098 5.94569 12.2405 6.37184 13.2679C6.79798 14.2953 7.51926 15.1733 8.44443 15.7907Z" stroke="#696969" strokeWidth="1.5" />
                                                        </svg>}
                                                </>}

                                                {location.type === 'Home' && <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.8164 29.3346V16.0013H20.8164V29.3346M4.81641 12.0013L16.8164 2.66797L28.8164 12.0013V26.668C28.8164 27.3752 28.5355 28.0535 28.0354 28.5536C27.5353 29.0537 26.857 29.3346 26.1497 29.3346H7.48307C6.77583 29.3346 6.09755 29.0537 5.59745 28.5536C5.09736 28.0535 4.81641 27.3752 4.81641 26.668V12.0013Z" stroke={location.active ? "#000000" : "#767676"} stroke-width="3" stroke-linecap="round" strokeLinejoin="round" />
                                                </svg>}
                                                {location.type === 'Office' && <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.1491 26V4.66667C21.1491 3.95942 20.8681 3.28115 20.368 2.78105C19.8679 2.28095 19.1897 2 18.4824 2H13.1491C12.4418 2 11.7636 2.28095 11.2635 2.78105C10.7634 3.28115 10.4824 3.95942 10.4824 4.66667V26M5.14909 7.33333H26.4824C27.9552 7.33333 29.1491 8.52724 29.1491 10V23.3333C29.1491 24.8061 27.9552 26 26.4824 26H5.14909C3.67633 26 2.48242 24.8061 2.48242 23.3333V10C2.48242 8.52724 3.67633 7.33333 5.14909 7.33333Z" stroke={location.active ? "#000000" : "#767676"} stroke-width="3" stroke-linecap="round" strokeLinejoin="round" />
                                                </svg>}
                                                {/* {location.active === true ?
                                                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.78516 9.0798L8.6423 15.9369L22.3567 2.22266" stroke="#F95424" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                        :
                                                        <svg onClick={() => deleteLocationapi(location._id)} className=" cursor-pointer   " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545"></path></svg>
                                                    } */}

                                                <div onClick={() => selectStoreApi(location._id)} className='cursor-pointer pl-2'>
                                                    <p className=" sm:text-sm text-md text-start font-semibold text-[#000000] "><label className=''>{location?.type === 'Home' || location?.type === 'Office' ? location?.type + ', ' : location.city}</label></p>
                                                    <p className="sm:text-sm text-md text-start font-normal text-[#8E8E93] "><label className=''>{location?.label ? location?.label + ', ' : ''} {location?.buildingtype ? location?.buildingtype + ', 2' : ''}</label>{location?.street}</p>
                                                </div>
                                            </div>
                                            <svg onClick={() => editLocation(location)} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.81641 19H7.24141L17.0164 9.225L15.5914 7.8L5.81641 17.575V19ZM3.81641 21V16.75L17.0164 3.575C17.2164 3.39167 17.4372 3.25 17.6789 3.15C17.9206 3.05 18.1747 3 18.4414 3C18.7081 3 18.9664 3.05 19.2164 3.15C19.4664 3.25 19.6831 3.4 19.8664 3.6L21.2414 5C21.4414 5.18333 21.5872 5.4 21.6789 5.65C21.7706 5.9 21.8164 6.15 21.8164 6.4C21.8164 6.66667 21.7706 6.92083 21.6789 7.1625C21.5872 7.40417 21.4414 7.625 21.2414 7.825L8.06641 21H3.81641ZM16.2914 8.525L15.5914 7.8L17.0164 9.225L16.2914 8.525Z" fill="#5A5A5A" />
                                            </svg>
                                        </div>
                                    ))}
                                </div>

                                    {/*  {userData?.addresses?.map((location, index) => (
                                        <div key={index} className='w-full bg-gray-100 mt-1 flex justify-between items-center p-3 rounded-md'>
                                            <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.8878 11.1658V11.1669C21.8872 12.9813 21.4061 14.7629 20.4939 16.3307L20.4858 16.341L20.4711 16.3631C19.9911 17.0801 19.4716 17.7698 18.9149 18.4289L18.9017 18.4445L18.8894 18.4608L12.5674 26.8302L12.5664 26.8316C12.4505 26.9858 12.3003 27.1109 12.1277 27.1971C11.9552 27.2833 11.7649 27.3281 11.572 27.3281C11.3791 27.3281 11.1889 27.2833 11.0163 27.1971C10.8437 27.1109 10.6936 26.9858 10.5777 26.8316L10.5766 26.8301L4.15462 18.3305C3.9271 18.0184 3.70006 17.741 3.49704 17.493C3.44328 17.4273 3.3912 17.3637 3.34124 17.302C3.0952 16.9983 2.88721 16.7265 2.70505 16.418L2.68171 16.3784L2.65372 16.342L2.65109 16.3386C1.86048 14.9805 1.39039 13.4597 1.27692 11.8919C1.16236 10.309 1.41446 8.72094 2.01363 7.25131C2.61281 5.78168 3.54291 4.47006 4.73159 3.41845C5.92027 2.36685 7.33549 1.60361 8.86721 1.18808C10.3989 0.772551 12.0059 0.715938 13.563 1.02264C15.1202 1.32934 16.5856 1.9911 17.8454 2.95644C19.1051 3.92178 20.1252 5.16469 20.8263 6.58851C21.5274 8.01233 21.8907 9.57868 21.8878 11.1658ZM8.44443 15.7907C9.3696 16.4081 10.4571 16.7373 11.5694 16.7365C13.06 16.7356 14.4893 16.1427 15.543 15.0883C16.5968 14.0339 17.1887 12.6043 17.1887 11.1136C17.1887 10.0013 16.8588 8.91403 16.2408 7.98925C15.6228 7.06448 14.7444 6.34378 13.7167 5.91829C12.689 5.49281 11.5583 5.38167 10.4674 5.59892C9.37656 5.81618 8.37465 6.35209 7.58841 7.13883C6.80217 7.92558 6.26691 8.92783 6.05036 10.0188C5.83381 11.1098 5.94569 12.2405 6.37184 13.2679C6.79798 14.2953 7.51926 15.1733 8.44443 15.7907Z" stroke="#696969" strokeWidth="1.5" />
                                            </svg>
                                            <div onClick={() => selectStoreApi(location._id)} className='cursor-pointer'>
                                                <p className="mb-1 sm:text-sm text-md text-start font-normal text-gray-500 "><label className=''>{location?.type} {location?.label ? location?.label + ', ' : ''} {location?.type ? location?.type + ', ' : ''}</label>{location?.street}</p>
                                            </div>
                                            {location.check ?
                                                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.78516 9.0798L8.6423 15.9369L22.3567 2.22266" stroke="#F95424" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                :
                                                <svg onClick={() => deleteLocationapi(location._id)} className=" cursor-pointer   " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545"></path></svg>
                                            }
                                        </div>
                                    ))} */}
                                </>
                                :
                                <>{savedLocations ? savedLocations.map((location, index) => (
                                    <div key={index} className='w-full bg-gray-100 mt-1 flex justify-between items-center p-3 rounded-md'>
                                        <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.8878 11.1658V11.1669C21.8872 12.9813 21.4061 14.7629 20.4939 16.3307L20.4858 16.341L20.4711 16.3631C19.9911 17.0801 19.4716 17.7698 18.9149 18.4289L18.9017 18.4445L18.8894 18.4608L12.5674 26.8302L12.5664 26.8316C12.4505 26.9858 12.3003 27.1109 12.1277 27.1971C11.9552 27.2833 11.7649 27.3281 11.572 27.3281C11.3791 27.3281 11.1889 27.2833 11.0163 27.1971C10.8437 27.1109 10.6936 26.9858 10.5777 26.8316L10.5766 26.8301L4.15462 18.3305C3.9271 18.0184 3.70006 17.741 3.49704 17.493C3.44328 17.4273 3.3912 17.3637 3.34124 17.302C3.0952 16.9983 2.88721 16.7265 2.70505 16.418L2.68171 16.3784L2.65372 16.342L2.65109 16.3386C1.86048 14.9805 1.39039 13.4597 1.27692 11.8919C1.16236 10.309 1.41446 8.72094 2.01363 7.25131C2.61281 5.78168 3.54291 4.47006 4.73159 3.41845C5.92027 2.36685 7.33549 1.60361 8.86721 1.18808C10.3989 0.772551 12.0059 0.715938 13.563 1.02264C15.1202 1.32934 16.5856 1.9911 17.8454 2.95644C19.1051 3.92178 20.1252 5.16469 20.8263 6.58851C21.5274 8.01233 21.8907 9.57868 21.8878 11.1658ZM8.44443 15.7907C9.3696 16.4081 10.4571 16.7373 11.5694 16.7365C13.06 16.7356 14.4893 16.1427 15.543 15.0883C16.5968 14.0339 17.1887 12.6043 17.1887 11.1136C17.1887 10.0013 16.8588 8.91403 16.2408 7.98925C15.6228 7.06448 14.7444 6.34378 13.7167 5.91829C12.689 5.49281 11.5583 5.38167 10.4674 5.59892C9.37656 5.81618 8.37465 6.35209 7.58841 7.13883C6.80217 7.92558 6.26691 8.92783 6.05036 10.0188C5.83381 11.1098 5.94569 12.2405 6.37184 13.2679C6.79798 14.2953 7.51926 15.1733 8.44443 15.7907Z" stroke="#696969" strokeWidth="1.5" />
                                        </svg>
                                        <div onClick={() => handelSubmit(location)} className='cursor-pointer'><p className="mb-1 sm:text-sm text-md text-start font-normal text-gray-500 "><label className=''>{location?.frmData?.business ? location?.frmData?.business + ', ' : ''} {location?.frmData?.location ? location?.frmData?.location + ', ' : ''}</label>{location?.street}</p></div>
                                        {location.active ?
                                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.78516 9.0798L8.6423 15.9369L22.3567 2.22266" stroke="#F95424" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            :
                                            <svg onClick={() => deleteLocation(index)} className=" cursor-pointer   " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545"></path></svg>}
                                    </div>
                                ))
                                    :
                                    <h3 className="p-3 text-xl text-center font-bold text-red-700  bg-red-100">Add address</h3>
                                }</>}
                        </div>
                    </div>
                    :
                    <div className="p-4 md:p-5 mt-2 ">
                        <div className='w-full p-2  flex justify-between items-center'>
                            <svg onClick={() => { setsavedLocation(true) }} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.1491 11.3568H2.48242M2.48242 11.3568L11.8158 20.6901M2.48242 11.3568L11.8158 2.02344" stroke="#303030" stroke-width="3" stroke-linecap="round" strokeLinejoin="round" />
                            </svg>
                            <h3 className="text-xl text-center font-bold text-gray-700 ">Address Info</h3>
                            <h3 onClick={() => deleteLocationapi(formData._id)} className="cursor-pointer text-xl text-center font-bold text-red-700 ">Remove</h3>
                        </div>
                        <div className="mb-2 w-full">
                            <div className='rounded  py-1 border-gray-200 mt-2'>
                                <p className=" text-sm text-start font-semibold text-gray-700 ">Address*</p>
                                <LoadScript
                                    googleMapsApiKey="AIzaSyDTF_IHcL1fDOskOfGnX_cYjcc2udxBfRU"
                                    libraries={libraries}
                                    loadingElement={<>Loading...</>}>
                                    <GoogleMap
                                        id="search-box-example"
                                        mapContainerStyle={{
                                            height: '40px',
                                            marginBottom: '0.5rem',
                                            display: 'flex',
                                            width: '100%',
                                            borderRadius: '0.25rem',
                                            background: 'white'
                                        }}
                                        className="mb-2 flex w-full  p-1 rounded border-2 border-gray-200"
                                        zoom={0}>
                                        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                                            <input type="text" placeholder="Address" defaultValue={formData?.street}
                                                style={{
                                                    boxSizing: 'border-box',
                                                    border: '1px solid transparent',
                                                    width: '100%',
                                                    height: '40px',
                                                    padding: '10px 12px',
                                                    borderRadius: '0.25rem',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                    fontSize: '14px',
                                                    outline: 'none',
                                                    borderColor: '#e5e7eb', // Matches `border-gray-200`
                                                    backgroundColor: '#F0F0F0',
                                                    textOverflow: 'ellipses',
                                                    position: 'absolute',
                                                    transition: 'background-color 0.2s, border-color 0.2s', // Matches `transition-colors`
                                                }}
                                                onBlur={(e) => {
                                                    e.target.style.backgroundColor = '#F0F0F0'; // Revert to default
                                                    e.target.style.borderColor = '#e5e7eb'; // Revert to default
                                                }}
                                                onFocus={(e) => {
                                                    e.target.style.backgroundColor = 'white'; // Matches `focus:bg-white`
                                                    e.target.style.borderColor = '#fb923c'; // Matches `focus:border-orange-500`
                                                }}
                                            />
                                        </StandaloneSearchBox>
                                    </GoogleMap>
                                </LoadScript>
                                {/* <p className=" text-md text-start font-normal text-gray-700 ">{formData?.street}</p> */}
                            </div>
                            <p className=" text-sm text-start font-semibold text-gray-700 ">Apt/ Suit/ Floor</p>
                            <input name="label" value={formData.label} onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 my-1 border-[1px] placeholder-gray-500 border-gray-200 rounded-md bg-[#F0F0F0] focus:bg-white focus:outline-none focus:border-orange-500 transition-colors" placeholder="Apt/Suite number (optional)" type="text" />
                            {/* <p className=" text-md text-start font-normal text-gray-700 ">Building type</p> */}
                            {/* <p className=" text-sm text-start font-semibold text-gray-700 ">Location type </p>
                            <div className="w-full flex gap-x-2 my-1 rounded-md focus:outline-none">
                                {["Home", "Office"].map((type) => (
                                    <div key={type} onClick={() => handleChange(type)}
                                        className={`w-1/4 flex items-center justify-center gap-x-2 px-1 py-2 my-1 border-2 rounded-md transition-colors ${formData.type === type ? "border-black bg-white" : "border-gray-200"} bg-[#F0F0F0] cursor-pointer`}>
                                        <svg width={type === "Home" ? "22" : "31"} height={type === "Home" ? "24" : "28"}
                                            viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            {type === "Home" ? (
                                                <path d="M9.81641 28.6901V15.3568H17.8164V28.6901M1.81641 11.3568L13.8164 2.02344L25.8164 11.3568V26.0234C25.8164 26.7307 25.5355 27.409 25.0354 27.9091C24.5353 28.4092 23.857 28.6901 23.1497 28.6901H4.48307C3.77583 28.6901 3.09755 28.4092 2.59745 27.9091C2.09736 27.409 1.81641 26.7307 1.81641 26.0234V11.3568Z"
                                                    stroke={formData.type === type ? "#303030" : "#767676"}
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            ) : (
                                                <path d="M21.1491 26.3555V5.02214C21.1491 4.31489 20.8681 3.63661 20.368 3.13652C19.8679 2.63642 19.1897 2.35547 18.4824 2.35547H13.1491C12.4418 2.35547 11.7636 2.63642 11.2635 3.13652C10.7634 3.63661 10.4824 4.31489 10.4824 5.02214V26.3555M5.14909 7.6888H26.4824C27.9552 7.6888 29.1491 8.88271 29.1491 10.3555V23.6888C29.1491 25.1616 27.9552 26.3555 26.4824 26.3555H5.14909C3.67633 26.3555 2.48242 25.1616 2.48242 23.6888V10.3555C2.48242 8.88271 3.67633 7.6888 5.14909 7.6888Z"
                                                    stroke={formData.type === type ? "#303030" : "#767676"}
                                                    strokeWidth="3"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            )}
                                        </svg>
                                        <p className="text-sm text-start font-normal text-gray-700">{type}</p>
                                        <input
                                            type="radio"
                                            id={type}
                                            name="type"
                                            value={type}
                                            onChange={(e) => handleChange(e.target.value)}
                                            checked={formData.type === type}
                                            className="hidden"
                                        />
                                    </div>
                                ))}
                                <div onClick={() => handleChange('Other')}
                                    className={`w-1/4 flex items-center justify-center gap-x-2 px-1 py-2 my-1 border-2 rounded-md transition-colors ${(formData.type !== 'Office' && formData.type !== 'Home') ? "border-black bg-white" : "border-gray-200"} bg-[#F0F0F0] cursor-pointer`}>

                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8164 9.02214V19.6888M8.48307 14.3555H19.1497M4.48307 2.35547H23.1497C24.6225 2.35547 25.8164 3.54938 25.8164 5.02214V23.6888C25.8164 25.1616 24.6225 26.3555 23.1497 26.3555H4.48307C3.01031 26.3555 1.81641 25.1616 1.81641 23.6888V5.02214C1.81641 3.54938 3.01031 2.35547 4.48307 2.35547Z" stroke={(formData.type !== 'Office' && formData.type !== 'Home') ? "#303030" : "#767676"} stroke-width="3" stroke-linecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <p className="text-sm text-start font-normal text-gray-700">Other</p>
                                    <input
                                        type="radio"
                                        id="Other"
                                        name="type"
                                        value={formData.type}
                                        onChange={(e) => handleChange('Other')}
                                        checked={formData.type === 'Other'}
                                        className="hidden"
                                    />
                                </div>
                            </div> */}
                            {/* ======================== */}
                            {/* <select className="w-full px-3 py-2 my-1 border-2 placeholder-gray-500 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors"
                                name="type" onChange={(e) => handleChange(e)}>
                                <option >Choose an Option</option>
                                <option value="Home">Home</option>
                                <option value="Office">Office</option>

                            </select> */}
                        </div>
                        {/* <div className="mb-2 w-full">
                            <input id="buss" name="business"
                                value={formData.business} onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 mb-1 border-2 placeholder-gray-500 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Business name (optional)" type="text" />
                        </div> */}

                        <LoadScript
                            googleMapsApiKey="AIzaSyDTF_IHcL1fDOskOfGnX_cYjcc2udxBfRU"
                            libraries={libraries}
                            loadingElement={<div>Loading...</div>}>
                            <GoogleMap
                                id="search-box-example"
                                mapContainerStyle={{ height: '200px', width: '100%', borderRadius: '10px' }}
                                zoom={13}
                                center={{ lat: formData.lat || formData?.coordinates?.lat, lng: formData.lng || formData?.coordinates?.lng }}                            >
                                <Marker position={{ lat: formData?.lat || formData?.coordinates?.lat, lng: formData.lng || formData?.coordinates?.lng }} />
                            </GoogleMap>
                        </LoadScript>
                        <button onClick={() => saveLocation()}
                            className={`block w-full mt-3 bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full p-3 font-semibold `}>
                            <i className="mdi mdi-lock-outline mr-1"></i>Saved Address</button>
                    </div>}
            </div>
        </div>
    )
}